import React from 'react';
import { P } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import { SimpleHeader } from '@dnb/header';

function PageIndex({ myParam }) {
  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>

      <SimpleHeader title={`Welcome to a dynamically routed page`} />

      <P>{`Do you like ${myParam}?`}</P>
    </>
  );
}

export default PageIndex;
